import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';

import ImageClimat from '../components/images/imageClimat.js'
import ImageConsolidation from '../components/images/ImageConsolidation.js'

const Climat = () => (
  <Layout>
    <SEO title="Climat de travail et santé organisationnelle | Gestion Mieux-Être" />
    <PageHeader
        header="Climat de travail et santé organisationnelle"
        text="Un diagnostic organisationnel de qualité vous permet de cerner la situation afin d’y voir plus clair et surtout de prendre les meilleures décisions!"
        quote="Ce n’est ni la finance, ni la stratégie, ni la technologie, mais le travail d’équipe qui demeure l’avantage compétitif ultime, parce qu’il est si puissant et si rare
            <span>- Patrick Lencioni</span>"
        background="climat"
         />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
        <p class="service-presentation">Pas de doute, nous sommes en mesure de vous soutenir afin de stimuler l'engagement de vos équipes afin de vous propulser vers l’avenir!</p>

        </ScrollAnimation>
        <br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="10"
                animateIn="fadeIn"
                className="half">
            <div>
                <Link to="climat-et-sante/harmonisation-du-climat-de-travail/">

                    <ImageClimat />
                    <h6>Harmonisation du climat de travail</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="half">
            <div>
                <Link to="climat-et-sante/consolidation-equipe/">
                    <ImageConsolidation />
                    <h6>Consolidation et mobilisation d'équipe</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
        <br /><br /><br />
        <ScrollAnimation
            delay="10"
            animateIn="fadeIn">
        <Link className="button-link" to="nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
        <br /><br />
    </div>
  </Layout>
)

export default Climat
